<template>

  <base-card color="secondary" outer-class="d-inline-flex" :outer-padding="false" inner-class="p-1 d-flex-soft flex-column" :stretch-inner="true">

    <div class="d-flex-soft text-success fw-600 mb-2 mt-1 mx-2 fs-0-9em">
      <i class="bi bi-telephone-outbound-fill me-2"></i>
      <div>
        Heeft u vragen?
      </div>
    </div>

    <base-card
      color="gray-100"
      style="opacity: 0.8; flex: 1;"
      :stretch-inner="true"
      inner-class="p-3 d-flex flex-lg-row flex-column flex-wrap align-items-center"
    >

      <div class="message me-4" style="min-width: 355px; flex: 1;">
        <h6>Bespreek uw situatie met een adviseur</h6>

        <p class="fs-0-9em mb-1">
          Komt u er niet uit, of lijkt isolatie niet mogelijk?<br/>
          Leg uw situatie voor aan een vakkundig adviseur!
        </p>
      </div>

      <div class="d-flex flex-column align-items-end mt-2 ms-auto">

        <base-button
          :icon="buttonIcon"
          type="button"
          variant="success"
          :disabled="busy || submitted"
          disabled-variant="minimal-success"
          @click="submitCallRequest"
        >
          {{buttonLabel}}
        </base-button>
                
        <transition name="fade-in">
          <base-button
            v-show="lead.meta.CallBackRequested"
            class="fs-0-8em p-1 m-2 mb-0"
            :icon="retractButtonIcon"
            type="button"
            variant="minimal-filled-danger"
            :disabled="retractBtnBusy"
            @click="retractCallRequest"
          >
            Verzoek intrekken
          </base-button>
        </transition>

      </div>
    </base-card>

  </base-card>
</template>

<script>
import BaseCard from "./BaseCard.vue";
import BaseButton from "./BaseButton.vue";
import { useAllStores } from "@/helpers/UseAllStores";
import { LeadDataService } from "@/services/LeadDataService";

export default {
  components: {
    BaseCard,
    BaseButton
  },
  setup() {
    return useAllStores();
  },
  data() {
    return {
      busy: false,
      retractBtnBusy: false
    }
  },
  computed: {
    submitted() {
      return this.lead.meta.CallBackRequested;
    },
    buttonLabel() {
      if (this.busy) {
        return 'Terugbelverzoek indienen...';
      } else if (this.submitted) {
        return 'Terugbelverzoek ingediend!';
      }
      return 'Bel mij terug';
    },
    buttonIcon() {
      if (this.busy) {
        return 'bi-gear-fill spin-infinite';
      } else if (this.submitted) {
        return 'bi-check-lg';
      }
      return 'bi-telephone-inbound-fill';
    },
    retractButtonIcon() {
      if (this.retractBtnBusy) {
        return 'bi-gear-fill spin-infinite';
      }
      return 'bi-x-lg';
    }
  },
  methods: {
    submitCallRequest() {
      this.busy = true;
      
      LeadDataService.submitCallRequest(this.uiStore.uuid)
      .catch(() => {
        console.log('Call request failed.');
      })
      .then(() => {
        this.busy = false;
      });
    },
    retractCallRequest() {
      this.retractBtnBusy = true;
      
      LeadDataService.retractCallRequest(this.uiStore.uuid)
      .catch(() => {
        console.log('Call request retract failed.');
      })
      .then(() => {
        this.retractBtnBusy = false;
      });
    }
  }
}
</script>