<template>
  <base-card class="w-100 px-4" color="danger" v-show="section.issues.length > 0 && expand" :stretch-inner="true">

    <h6 class="d-flex-soft align-items-center text-danger fs-0-8em opacity-75">
      <i class="bi bi-exclamation-triangle-fill me-2"></i>
      Mogelijke problemen
    </h6>

    <ul class="section-issues-list">
      <li
        v-for="issue in section.issues"
        :key="issue"
        :class="{['issue-'+issue.type]: true, 'issue-view-link': issue.view}"
        @click="navigateToIssueView(issue)"
      >
        <strong v-html="issue.title" class="me-2"></strong>
        <i class="bi bi-pencil-fill text-danger" v-if="issue.view"></i>
        <div class="description" v-html="issue.description" v-if="issue.description"></div>
      </li>
    </ul>

  </base-card>
</template>

<script>
import BaseCard from '@/components/BaseCard.vue';

export default {
  components: {
    BaseCard
  },
  props: {
    section: {
      type: Object
    },
    expand: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    navigateToIssueView(issue) {
      if (issue && issue.view) {
        this.$emit('nav-to-issue', issue.view);
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .section-issues-list {
    padding: 0 0;
    padding-left: map-get($spacers, 3);
    margin: 0 0;
    font-size: 0.9em;

    li {
      border-radius: $border-radius;
      margin: map-get($spacers, 3) 0;
      padding: map-get($spacers, 2);
      strong {
        font-weight: 600;
      }
      &:last-child {
        margin-bottom: 0px;
      }

      &.issue-view-link {
        cursor: pointer;
        transition: background-color 0.1s ease, box-shadow 0.2s ease;
        &:hover {
          background-color: rgba($white, 0.2);
          @include std-box-shadow(3, 2, $danger);
        }
      }

      &.issue-error {
        strong {
          color: $danger;
        }
      }
      &.issue-warning {
        strong {
          color: $warning;
        }
      }
    }
  }
</style>